<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CRow>
          <CCol sm="12" class="text-center">
            <act-meter-filter :users="users"
                          :rows="null"
                          v-on:set-period="setPeriod"
                          v-on:get-data="getData"
                          v-on:reset-data="resetData"/>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CCard>
              <CRow>
                <CCol sm="1">

                </CCol>
                <CCol sm="2">
                  <label>
                    Период
                  </label>
                  <date-range-picker
                    :locale-data="localeData"
                    v-model="dateRange"
                    :timePicker="false"
                    :timePicker24Hour="false"
                    :show-dropdowns="true"
                    :ranges="false"
                    @update="updateValues"
                  >
                    <template #input="picker" style="min-width: 350px;">
                      {{ picker.startDate | date }} - {{ picker.endDate | date }}
                    </template>
                  </date-range-picker>
                  </CCol>
                  <CCol sm="2">
                    <label>&nbsp;</label>
                   <CButton  block color="secondary"
                            @click="resetForm">
                    сбросить
                  </CButton>
                  </CCol>
              </CRow>
            </CCard>
          </CCol>
        </CRow>
        <CCard>
          <CCardHeader>Статистика</CCardHeader>
          <CCardBody>
            <CChartBarStatistic
              :labels="items.labels"
              title="Статистика поверок"
              :datasets="items.datasets"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

  </div>
</template>

<script>

import CChartBarStatistic from '../charts/CChartBarStatistic.vue';
import ActMeterFilter from '../base/ActMeterFilter.vue'
import {ADDRESS} from "../../backserver";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

const axios = require("axios");

export default {
  name: 'Statistic',
  components: { CChartBarStatistic, ActMeterFilter, DateRangePicker },
  data() {
    return {
      items: [],
      worker: {value: null, label: ''},
      startDate: null,
      endDate: null,
      minDate: null,
      maxDate: null,
      dateRange: {
        startDate: '2021-09-01',
        endDate: '2021-09-30',
      },
      localeData: {
        direction: 'ltr',
        format: 'dd/mm/yyyy',
        separator: ' - ',
        applyLabel: 'Применить',
        cancelLabel: 'Отмена',
        weekLabel: 'Нед',
        customRangeLabel: 'Выбранный период',
        daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        monthNames: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
        firstDay: 0
      }
    }
  },
  created() {
    this.getData();
  },
  filters: {
    dateCell (value) {
      let dt = new Date(value)

      return dt.getDate()
    },
    date (val) {
      return val ? new Intl.DateTimeFormat('en-GB').format(val) : ''
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    users: function () {
      let options = [
        {value: this.$store.getters.user.id, label: this.$store.getters.user.name},
      ];
      if (this.$store.getters.user.slave_users) {
        this.$store.getters.user.slave_users.forEach((item) => {
          if (item.slave)
            options.push({value: item.slave_id, label: item.slave.name})
        });
      }
      return options;
    },
  },
  watch: {
    worker(val) {
      let data = this.users.filter(item => item.value===val);
      let worker = data.length===1 ? data[0] : {value: null, label: null};
      this.$store.dispatch('SET_WORKER', worker);
      if (val)
        this.getData();
    },
  },
  methods: {
    getData() {
      axios.post(`/api/get-statistic/${this.$store.getters.worker.value}`)
        .then((resp) => {
          this.items = resp.data.data;
        });
    },
    getDataDays() {
      let data =  {startDate: this.dateRange.startDate, endDate: this.dateRange.endDate};

      axios.post(`/api/get-statistic-days/${this.$store.getters.worker.value}`, data)
        .then((resp) => {
          this.items = resp.data.data;
        });
    },
    resetData(user) {
      this.getData(user);
    },
    setPeriod(startDate, endDate) {
      this.startDate = startDate;
      this.endDate = endDate;
      this.currentPage = 1;
      this.getData(this.user);
    },
    defaultDateRange(all) {
      let now = new Date();
      let y = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(now);
      let m = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(now);

      this.dateRange.startDate = all ? `2018-01-01` :`${y}-${m}-01`;
      this.dateRange.endDate = `${y}-${m}-${this.getLastDayOfMonth(y,m)}`;
    },
    getLastDayOfMonth(year, month) {
      let date = new Date(year, month, 0);
      return new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    },
    updateValues  () {
      // console.log('set-period', this.dateRange.startDate, this.dateRange.endDate);
      this.getDataDays();
    },
    resetForm()
    {
      this.number_act = null;
      this.defaultDateRange(true);
      this.getData()
    },
  }
}
</script>
